import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { useState } from 'react'
import * as DOCUMENTS_REQUESTS from '../../api/documents'
import * as SUPERADMIN_REQUESTS from '../../api/superAdmin'
import { BUCKET_BASE_URL } from '../../utils/config'

const SuperAdminMedals = () => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [userEmail, setUserEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [medal, setMedal] = useState('')

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    setMedal(event.target.files[0])

    if (file) {
      setSelectedImage(URL.createObjectURL(file))
    }
  }

  const handleGiveMedal = (file) => {
    const name = Date.now()
    const extension = file.type.split('/')[1]

    setLoading(true)

    DOCUMENTS_REQUESTS.generatePresignedUrl({ name, extension })
      .then((presignedUrl) => {
        return DOCUMENTS_REQUESTS.uploadDocumentS3(presignedUrl, file)
      })
      .then(() => {
        return SUPERADMIN_REQUESTS.giveMedalToChallenger({
          medalUrl: `${BUCKET_BASE_URL}/${name}.${extension}`,
          userEmail: userEmail
        })
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <TextField
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        placeholder="Challenger Email"
        sx={{ width: 300 }}
      />
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-picker"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-picker">
        <Button variant="outlined" component="span" sx={{ width: 150, height: 50 }}>
          Choose Medal
        </Button>
      </label>

      <Button
        onClick={() => handleGiveMedal(medal)}
        disabled={!userEmail || !medal || loading}
        variant="contained"
        sx={{ width: 150, height: 50 }}
        component="span">
        {loading ? <CircularProgress size={25} /> : 'Give Medal'}
      </Button>
      {selectedImage && (
        <Box>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ maxWidth: '100%', maxHeight: '300px' }}
          />
        </Box>
      )}
    </Box>
  )
}

export default SuperAdminMedals
